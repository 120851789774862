<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ $t("sakSettings") }}</v-toolbar-title>
    </v-toolbar>
    <v-card-actions>
      <v-spacer />
      <v-btn text :href="changePasswordUrl" target="_blank">
        {{ $t("change password") }}
        <v-icon right> mdi-open-in-new </v-icon>
      </v-btn>
      <v-btn text :href="mfaUrl" target="_blank">
        {{ $t("setup MFA") }}
        <v-icon right> mdi-open-in-new </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "SakSettings",
  computed: {
    changePasswordUrl() {
      return (
        this.$config.passwordChangeUrl +
        "#" +
        encodeURI(this.$store.getters.extraData.access_token)
      );
    },
    mfaUrl() {
      return (
        this.$config.mfaUrl +
        "#" +
        encodeURI(this.$store.getters.extraData.access_token)
      );
    },
  },
};
</script>

<i18n>
{
  "en": {
   "sakSettings": "Account settings",
   "change password": "Change password",
   "setup MFA": "Multi factor authentication"
  },
  "de": {
    "sakSettings": "Zugangseinstellungen",
    "change password": "Passwort ändern",
    "setup MFA": "Multi Faktor Authentifizierung"
  }
}
</i18n>
